import React from 'react';

import ServicesPage from './../../components/ServicesPage';
import { TextSection, SectionHeading, Declaration, UL, LI } from '../../components/textComponents';

import snowRemovalPhoto from '../../../static/assets/snow_plows.jpg';

const keywords = [
  'VLP',
  'Landscaping',
  'Landscaper',
  'Matt Vojticek',
  'Northeast Ohio',
  'Snow Removal',
  'Snow Plow',
  'Shovel',
  'De-ice',
];

const page = {
  title: 'Snow Removal',
  keywords: keywords,
  description: 'VLP Landscaping is available 24/7 to remove snow from driveways, sidewalks, parking lots, and roads.',
};

const heading = {
  title: page.title,
  img: snowRemovalPhoto,
  alt: 'Three snow plows ready for winter.'
};

export default () => (
  <ServicesPage page={page} heading={heading}>
    <TextSection>
      <SectionHeading>Commercial</SectionHeading>
      <Declaration>
        VLP Landscaping understands that your customers are number one and should be protected at all times of the year.  We provide on-time 24-hour snow removal services that allow for customers to continue coming through those doors.</Declaration>
      <Declaration>
        Services we provide:
      </Declaration>
      <UL css={`
        margin-bottom: 5rem;
      `}>
        <LI>24 hour availability</LI>
        <LI>Parking lot snow removal</LI>
        <LI>Sidewalk snow removal</LI>
        <LI>Heavy removal</LI>
        <LI>Salting/de-icing</LI>
      </UL>
      <SectionHeading>Residential</SectionHeading>
      <Declaration>
        VLP Landscaping can provide you with the ease of a snow cleared driveway all winter long.  Let your backs rest and enjoy the warmth of your house.
      </Declaration>
      <Declaration>
        Services we provide:
      </Declaration>
      <UL css={`
        margin-bottom: 5rem;
      `}>
        <LI>Removing snow from driveways and sidewalks</LI>
        <LI>Salting/de-icing</LI>
      </UL>
    </TextSection>
  </ServicesPage>
);
